div {
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Montserrat:wght@400;500&display=swap");

.hr {
  border-bottom: 1px solid #eaeaea;
  padding: 0.5rem 0;
  /* border-top:1px solid #eaeaea !important; */
  /* border:1px solid #eaeaea !important; */
}

.line-clamp5 {
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}

.searchbar {
  max-width: 500px;
  display: flex;
  margin-bottom: auto;
  margin-top: auto;
  height: 32px;
  /* background: #0f75bc; */
  border-radius: 20px;
  /* border: 1px solid #bdbdbd; */
}

.fadeInWidth {
  animation: 0.2s linear fadeInWidth;
}

.fadeOutWidth {
  animation: 0.2s linear fadeOutWidth;
}

.fadeIn {
  animation: 0.5s linear fadeIn;
}
.fadeOut {
  animation: 0.5s linear fadeOut;
}

.search-input {
  color: white;
  border: 0;
  /* border-radius: 10px; */
  outline: 0;
  /* background: none; */
  width: 350px;
  padding: 0px 15px;
  transition: width 0.4s linear;
  background-color: #0f75bc;
  /* caret-color: grey; */
  animation-name: fadeIn;
  animation-duration: 0.5s;
  font-size: 14px;
  border-bottom: 1px solid #ebebeb;
}

.search-input::placeholder {
  color: white !important; /* Placeholder text color */

  /* Add any other styles you want */
}

.search-icon {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 22px;
  color: #fff;
  background-color: #0f75bc;
}

.icon-bg {
  display: flex;
  justify-content: center;
  /* background-color: #0f75bc; */
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 20px;
}
.icon-bg:hover {
  opacity: 0.8;
  cursor: pointer;
}

.marginNav {
  /* margin-right: 15px;
  margin-left: 15px;
  margin-top: 20px; */
  margin: 10px 0px 10px 0px;
}

#basic-nav-dropdown {
  color: black !important;
  font-weight: bold;
}

@keyframes fadeInWidth {
  0% {
    width: 30px;
  }
  100% {
    width: 350px;
  }
}
@keyframes fadeOutWidth {
  0% {
    width: 350px;
  }
  100% {
    width: 30px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.navbar-expand-md .navbar-collapse {
  align-items: flex-end;
  justify-content: flex-end;
}

.btn-sm {
  background-color: white;
  /* color: white; */
  padding: 10px;
  width: 130px;
  border-radius: 4px;
  margin-right: 30px;
  border: transparent;
}

.btn-sm:hover {
  background-color: black;
  color: white;
  border: transparent;
  padding: 10px;
}

.navbar-expand .navbar-nav .dropdown-menu {
  /* width: 800px !important; */
  background-color: transparent;
  border: none !important;

  /* color: black; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* border-radius: 15px; */
}

/* .dropdownLeader1 {
  width: 800px;
  background-color: white;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 15px;
  margin-left: -190px;
  margin-top: 10px;
} */

.dropdownLeader1 {
  width: 1200px;
  background-color: white;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 15px;
  margin-left: -270px;
  margin-top: 10px;
}


.dropdownLeader2 {
  width: 1200px;
  background-color: white;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 15px;
  /* margin-left: -550px; */
  /* margin-left: -468px; */
  margin-left: -400px;
  margin-top: 10px;
}

.dropdownLeader3 {
  width: 1200px;
  background-color: white;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 15px;
  margin-left: -550px;
  /* margin-left: -468px; */
  /* margin-left: -400px; */
  margin-top: 10px;
}

/* .dropdown-menu[data-bs-popper] {
  left: -180px !important;
    top: 60px;
} */

.dropdown-item {
  white-space: unset !important;
}

.dropdown-item:hover {
  color: #0f75bc !important;
  background-color: white !important;
}

.navImg {
  width: 35%;
  border-radius: 10px;
}

.flex-ended {
  text-align: flex-end !important;
  display: block;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 10px;
}

.flex-ended:hover {
  color: #0f75bc !important;
}

.navbar {
  padding: 0px;
  --bs-navbar-brand-padding-y: 0 !important;
}


.navbar-brand{
  padding-top:0;
  padding-bottom: 0;
}
.gryBoxRepo {
  height: 45px;
  line-height: 1;
}

.croossIcon {
  font-size: 14px;
}

.croossIcon:hover {
  color: #f7eaea;
}

.ended {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
}

/* 
.fixedNav{
  overflow: hidden;
  position: fixed; 
  top: 0; 
  width: 100%; 
  
} */

.ScroolTopBtn {
  /* background-color: #f46363;*/
  font-size: 15px;
  border: transparent;
  /* position: fixed;
  margin-top: 400px; */
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: black;
  /* padding: 2.5em 1.5em 0; */

  font-size: 16px;
  border-top-right-radius: 12px;
  height: auto !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;

  margin-top: -25px !important;
}

a {
  color: white;
  text-decoration: none;
  margin-top: 10px;
}

a:hover {
  color: #0f75bc;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: transparent !important;
}

.menu-content {
  max-height: 100vh; /* Set your desired maximum height */
  overflow-y: auto; /* Enable vertical scrolling if the content exceeds the maximum height */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Microsoft Edge */
  padding: 0px 0px 40px 0px;
  top: 84px;
}

.menu-content a {
  text-decoration: none;
  color: white;
}

.menu-content a:hover {
  color: #0f75bc;
}

.menu-content::-webkit-scrollbar {
  width: 0; /* Hide the scrollbar for WebKit (Safari, Chrome, and new Edge) */
}

.bm-menu-wrap {
  left: 0;
}

.close-button {
  font-size: 20px;
  color: black;
}

.close-button:hover {
  color: #f46363;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #000;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* .cross-icon {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
  animation: rotateAnimation 2s alternate infinite;
}

.hamburger-icon {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.form-control {
  font-size: 14px;
  font-weight: 500;
}

.start {
  display: flow;
  text-align: center;
}

.modal-backdrop {
  --bs-backdrop-opacity: 1;
}

.modal {
  /* top: 130px; */
  /* width: 900px; */
  --bs-modal-width: 1000px !important;
}

.modal-dialog {
  margin-top: 130px;
}

.btn-sm {
  transition: all 0.2s ease;
  user-select: none;
}

.btn-sm:active {
  transform: translate(0.1875rem, 0.1875rem);
}

.paraMob {
  margin: auto;
}

.popupBack {
  /* background-image: url(https://st3.depositphotos.com/1005233/18006/i/450/depositphotos_180063676-stock-photo-message-illuminated-symbol.jpg); */
  /* background-repeat: no-repeat;
  background-size: cover; */
  /* background-image: linear-gradient(to top, #d299c2 0%, #fef9d7 100%); */
  /* background-image: linear-gradient(to right, #f46363, #f7706f, #f97c7b, #fb8787, #fd9393); */
  /* background-color: white; */
  /* background-image: url(/public/red-blurred-background-vector.jpg); */
  /* background-repeat: no-repeat; */
  background-color: #ebebeb;

  /* width: 100%; */
  /* background-position-x: 100%;
  background-position-y: 100%; */
  border-radius: 5px;
  /* background-image: linear-gradient(to right top, #b4aeda, #a0bde0, #9ac8db, #a5d0d2, #b9d6cb, #c2d8ca, #cad9cb, #d2dbcd, #d0dbce, #cddccf, #cbdcd0, #c8dcd1); */
}

.martop {
  margin-top: 250px;
}

.letter-image {
  position: absolute;
  top: 37%;
  left: 59%;
  /* width: 200px; */
  height: 200px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.animated-mail {
  position: absolute;
  height: 150px;
  width: 200px;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;

  .body {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 100px 200px;
    border-color: transparent transparent #0f75bc transparent;
    z-index: 2;
  }

  .top-fold {
    position: absolute;
    top: 50px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 100px 0 100px;
    -webkit-transform-origin: 50% 0%;
    -webkit-transition: transform 0.4s 0.4s, z-index 0.2s 0.4s;
    -moz-transform-origin: 50% 0%;
    -moz-transition: transform 0.4s 0.4s, z-index 0.2s 0.4s;
    transform-origin: 50% 0%;
    transition: transform 0.4s 0.4s, z-index 0.2s 0.4s;
    border-color: #cf4a43 transparent transparent transparent;
    z-index: 2;
  }

  .back-fold {
    position: absolute;
    bottom: 0;
    width: 200px;
    height: 100px;
    background: #cf4a43;
    z-index: 0;
  }

  .left-fold {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 0 50px 100px;
    border-color: transparent transparent transparent #e15349;
    z-index: 2;
  }

  .letter {
    left: 20px;
    bottom: 0px;
    position: absolute;
    width: 160px;
    height: 60px;
    background: white;
    z-index: 1;
    overflow: hidden;
    -webkit-transition: 0.4s 0.2s;
    -moz-transition: 0.4s 0.2s;
    transition: 0.4s 0.2s;

    .letter-border {
      height: 10px;
      width: 100%;
      background: repeating-linear-gradient(
        -45deg,
        #cb5a5e,
        #cb5a5e 8px,
        transparent 8px,
        transparent 18px
      );
    }

    .letter-title {
      margin-top: 10px;
      margin-left: 5px;
      height: 10px;
      width: 40%;
      background: #cb5a5e;
    }
    .letter-context {
      margin-top: 10px;
      margin-left: 5px;
      height: 10px;
      width: 20%;
      background: #cb5a5e;
    }

    .letter-stamp {
      margin-top: 30px;
      margin-left: 120px;
      border-radius: 100%;
      height: 30px;
      width: 30px;
      background: #cb5a5e;
      opacity: 0.3;
    }
  }
}

.shadow {
  position: absolute;
  top: 200px;
  left: 50%;
  width: 400px;
  height: 30px;
  transition: 0.4s;
  transform: translateX(-50%);
  -webkit-transition: 0.4s;
  -webkit-transform: translateX(-50%);
  -moz-transition: 0.4s;
  -moz-transform: translateX(-50%);

  border-radius: 100%;
  background: radial-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}

.letter-image:hover {
  .animated-mail {
    transform: translateY(50px);
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
  }

  .animated-mail .top-fold {
    transition: transform 0.4s, z-index 0.2s;
    transform: rotateX(180deg);
    -webkit-transition: transform 0.4s, z-index 0.2s;
    -webkit-transform: rotateX(180deg);
    -moz-transition: transform 0.4s, z-index 0.2s;
    -moz-transform: rotateX(180deg);
    z-index: 0;
  }

  .animated-mail .letter {
    height: 180px;
  }

  .shadow {
    width: 250px;
  }
}

.searchBox {
  position: absolute;
  /* background-color: #0f75bc; */
  background-color: white;
  margin-top: 32px;
  /* width: 27%; */
  width: auto;
  max-width: 50%;
  border-radius: 10px;
  padding-left: 0px;
  /* height: 316px; */
  height: auto;
  overflow-x: hidden;
}

.searchField {
  margin-top: 8px;

  list-style: none;
  width: 100%;
  padding: 0px 10px 0px 10px;
  font-size: 13px;
  font-weight: 500;
}

.searcMainBox {
  position: fixed;
  width: 74%;
}

.noResult {
  margin-top: 30px;
  margin-left: 15px;
}

.allResult {
  text-align: center;
  color: white;
  /* align-items: center; */
  margin: auto;
  display: block;
  padding: 5px;
  background-color: #0f75bc;
  font-weight: 500;
  border-top: 1px solid #d9d9d9;
  font-size: 14px;
}

.allResult,
a:hover {
  color: white;
}

.laptopmImgback {
  align-items: center;
  display: grid;
}

.notFound {
  text-align: center;
  font-size: 16px;
  color: black;
}

.subslogo {
  width: 20% !important;
  height: 50px !important;
  object-fit: contain;
  left: 40%;
  position: absolute;
  background-color: #0f75bc;
}

.modal-header {
  padding: 20px !important;
  background-color: #0f75bc;
}

.custom-dropdown:hover .dropdown-menu {
  display: block;
}

.MobileResponsive {
  display: none;
}

@media (max-width: 425px) {
  .icon-bg {
    /* justify-content: end;
    align-items: end;
    width: 100%; */
    margin-right: 8px;
    /* margin-top: -6px; */
  }

  .MainLogo{
    margin-left: 65px;
  }
  /* 
  .searchBox{
    width: 100%;
  } */
}

@media (min-width: 375px) and (max-width: 425px) {
  .searchBox {
    width: 90% !important;
    max-width: 100%;
  }

  .search-input {
    border-bottom: none;
  }
  .navbar-brand{
    padding-top:0 !important;
    padding-bottom: 0 !important;
  }
  .MainLogo{
    margin-left: 60px !important;
  }
}

@media (max-width: 576px) {
  .searchBox {
    width: 100% !important;
    max-width: 100%;
  }


}



@media (max-width: 576px) {
  .DesktopResponsive {
    display: none !important;
  }

  .MainLogo{
    margin-left: 72px;
  }
  .SubBtn {
    margin: auto !important;
  }
  .letter-image {
    position: absolute;
    top: 32%;
    left: 50%;
    width: 200px;
    height: 200px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .mobileinputsubscribe {
    margin-top: 165px;
  }

  .subslogo {
    width: 30% !important;
    left: 30%;
    margin: auto;
  }

  .search-input {
    border-bottom: none;
  }
}

@media (max-width: 767px) {
  .DesktopResponsive {
    display: none !important;
  }

  .subBtn {
    margin: auto !important;
  }
  .searchField {
    line-height: 1.3;
  }

  .searchBox {
    width: 100%;
    max-width: 87%;
  }

  .dropdownLeader2 {
    margin-left: 0px;
    width: 0px;
  }

  .imgMob {
    display: none;
  }
  .MobileResponsive {
    display: block !important;
  }
/* 
  .marginNav {
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
  } */

  .marginNav{
    margin: 10px 0 0px 10px;
  }

 

  .MainLogo {
    /* width: 80%;
   
    display: block !important;
    text-align: center !important; */
    width: 70% !important;
  /* margin-left: 35px; */
  
    text-align: center !important;
   
  }

  .logoImp {
    text-align: center !important;
    margin-left: auto !important;
    margin-right: auto !important;

    
  
  }

  .btn-sm {
    margin: 0px;
  }

  .search-input {
    width: auto;
    background-color: #ebebeb;
    color: #000;
    animation-name: fadeIn;
    animation-duration: 0s;
  }


  .search-input::placeholder{
    color: #000 !important;
  }
  .nav-link {
    color: white !important;
  }

  .tabBtn {
    color: black !important;
  }

  .btn-sm {
    width: 100%;
    background-color: #0f75bc;
    color: white;
    border: transparent;
  }

  .gryBoxRepo {
    height: 0px;
    line-height: none;
  }

  .search-icon {
    font-size: 25px;
    background-color: black;
  }
  /*   
  .searchDisplay{
    display: flex;
    justify-content: flex-end;
  } */

  .search-input {
    width: 245px;
  }

  .ended {
    margin-bottom: 0px;
  }

  @keyframes fadeInWidth {
    0% {
      width: 0px;
    }
    100% {
      width: 0px;
    }
  }
  @keyframes fadeOutWidth {
    0% {
      width: 0px;
    }
    100% {
      width: 0px;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

@media (max-width: 1920px) {
  .searchBox {
    width: 25%;
  }
}

@media (max-width: 992px) {
  .dropdownLeader2 {
    margin-left: -100px !important;
    width: 625px !important;
  }

  .dropdownLeader1 {
    margin-left: -17px;
    width: 540px;
  }

  .dropdownLeader3 {
    margin-left: -262px !important;
    width: 625px !important;
  }

  .imgMob {
    display: none;
  }

  .paraMob {
    width: 100% !important;
  }

  .searchBox {
    width: 67%;
  }
}

@media (max-width: 1200px) {
  .dropdownLeader2 {
    margin-left: -400px;
    width: 900px;
  }

  .searchBox {
    width: 45%;
  }
}

@media (max-width: 1400px) {
  .searchBox {
    width: 38%;
  }

  .modal-dialog {
    margin-top: 30px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .search-input {
    width: auto;
    transition: none;
  }

  .fadeInWidth {
    animation: none;
  }

  .fadeOutWidth {
    animation: none;
  }

  .fadeIn {
    animation: none;
  }
  .fadeOut {
    animation: none;
  }
}
