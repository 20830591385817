




body{
  font-family: 'Lato', sans-serif;
font-family: 'Montserrat', sans-serif;
}

.marLR{
  margin-right: 50px;
  margin-left: 50px;
}

.NavMArLR{
  margin-right: 30px;
  margin-left: 30px;
}

p{
  color: black !important;
  font-weight: 500;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  
}

h1, h2, h3, h4, h5, h6{
  color: black !important;
}

.btn-sm:hover{
  color: #0f75bc;
}


.box-Card1 .card:hover{
  color: #0f75bc !important;
}
.just-text{
  text-align: justify;
  line-height: 1.4;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.nav-link{
  color: black !important;
}

.container-max{
  width: 100%;
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  padding: 0 30px;
  max-width: 1440px;
}

.bllack{
  color: black;
  opacity: 0.3;
  text-align: center;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  margin: auto;
  line-height: 150px;
  height: 200px;
  text-align: center;

}

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Montserrat:wght@400;500&display=swap');




.PaginatinNextBtn{
  border: none;
}

.paginationBox{
    text-align: center;
    margin: auto;
    justify-content: center;
    align-items: center;
    gap: 15px;
    display: flex;
}

.redColor{
  color: #0f75bc;
}

























@media (max-width: 576px) {

   
   
}

@media (max-width: 767px) {
 
  

  .marLR{
    margin-right: 5px;
    margin-left: 5px;
  }

  .NavMArLR{
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px !important;
  }
  
}

@media (max-width: 992px) {

 
}

@media (max-width: 1200px) {
  
}

@media (max-width: 1400px) {
}
