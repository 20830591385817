

.btn-sm1{
    padding: 3px 10px 3px 10px;
    background-color: white;
    color: black;
    border-radius: 3px;
    margin-left: 10px;
}

.btn-sm1:hover{
    background-color: #0f75bc;
    color: white;
}


.box-Card{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}


.intervieBox{
    display: flex;
}

.learningBox{
    display: flex;
}


.guestImg{
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: end;
}

.guestText{
    margin-top: 40px;
    margin-bottom: 40px;
    align-items: center;
    display: flex;
}

.guestFont{
    font-size: 40px;
}

.ImgBoxGuets{
    width: 85%;
    border-radius: 15px;
}

.ImgBoxGuets2{
    width: 80%;
    border-radius: 15px;
}

.guestPtag{
    font-size: 18px;
}

.quickImgBox{
    width: 26%;
}

.cardText{
    font-size: 12px;
}







.column {
    margin: 15px 15px 0;
    padding: 0;
  }
  
  .column:last-child {
    padding-bottom: 60px;
  }
  
  .column::after {
    content: '';
    clear: both;
    display: block;
  }
  
  .column div {
    position: relative;
    float: left;
    width: 300px;
    height: 200px;
    margin: 0 0 0 25px;
    padding: 0;
  }
  
  .column div:first-child {
    margin-left: 0;
  }
  
  .column div span {
    position: absolute;
    bottom: -20px;
    left: 0;
    z-index: -1;
    display: block;
    width: 300px;
    margin: 0;
    padding: 0;
    color: #444;
    font-size: 18px;
    text-decoration: none;
    text-align: center;
    transition: 0.3s ease-in-out;
    opacity: 0;
  }
  
  /* Styles for images with hover effect */
  figure {
    /* width: 300px; */
    height: auto; /* Adjusted height for your specific case */
    margin: 0;
    padding: 0;
    background: #fff;
    overflow: hidden;
    border-radius: 20px;
  }
  
  figure:hover + span {
    bottom: -36px;
    opacity: 1;
    
  }
  
  /* Zoom In #1 */
  .hover01 figure img {
    transform: scale(1);
    transition: 0.3s ease-in-out;
  }
  
  .hover01 figure:hover img {
    transform: scale(1.3);
  }










  

.line-clamp3 {
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;  
    overflow: hidden !important;
  }
  


.btn-sm1.active {
    /* Styles for the active state */
    color: white;
    background-color: #0f75bc;
    /* Add any other styles you want to apply when the link is active */
  }





























@media (max-width: 576px) {

   
   
}

@media (max-width: 767px) {
 
    .tabMobile{
        border: 1px solid grey;
        height: 40px;
        width: 250px;
    }

    
.intervieBox{
    display: grid;
}

.intervieBox .box-Card{
    margin-top: 20px;
        
}

.learningBox{
    display: grid;
}

.guestImg{
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: start;
}

.guestText{
    margin-top: 5px;
    margin-bottom: 5px;
    align-items: center;
    
}

.guestFont{
    font-size: 22px;
    width: 90%;
}

.btn-pod{
    margin-top: 10px;
}

.ImgBoxGuets{
    width: 100%;
}

.ImgBoxGuets2{
    width: 100%;
}

.guestPtag{
    font-size: 15px;
}

.guestFont2{
    font-size: 22px;
}
    
.quickImgBox{
    width: 50%;
}

.quickText{
    font-size: 14px;
}
    
  
}

@media (max-width: 992px) {
 
 
}

@media (max-width: 1200px) {
 
}

@media (max-width: 1400px) {
 
}



.btn-sm1{
    padding: 3px 10px 3px 10px;
    background-color: white;
    color: black;
    border-radius: 3px;
    margin-left: 10px;
}

.btn-sm1:hover{
    background-color: #0f75bc;
    color: white;
}


.box-Card{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}


.intervieBox{
    display: flex;
}

.learningBox{
    display: flex;
}


.guestImg{
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: end;
}

.guestText{
    margin-top: 40px;
    margin-bottom: 40px;
    align-items: center;
    display: flex;
}

.guestFont{
    font-size: 40px;
}

.ImgBoxGuets{
    width: 85%;
    border-radius: 15px;
}

.ImgBoxGuets2{
    width: 80%;
    border-radius: 15px;
}

.guestPtag{
    font-size: 18px;
}

.quickImgBox{
    width: 26%;
}

.cardText{
    font-size: 12px;
}

.sidenav {
    /* height: 21vh; */
    height: 0;
    animation: slideDown 0.6s forwards;
   
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    transition: 0.3s;
    /* left: 300px; */
    background-color: black;
    overflow-x: hidden;
    /* overflow-y: scroll;  */
    padding-top: 60px;
    transition: 0.3s;
    /* border-top-right-radius: 10px; */
    border-radius: 10px;
    /* left: 28.5%; */
    display: flex;
  }


  @keyframes slideDown {
    0% {
        top: -120px; /* Start position */
    }
    100% {
        top: 0px; /* End position */
    }
  }
  

.sidenav li {
    margin-top: 27px !important;
  
    /* padding: 0px 10px 0px 10px; */
    display: block;
    transition: 0.6s;
    color: white;
    width: auto;
    /* border-radius: 5px; */
    cursor: pointer;
    background-color: black;
    /* font-weight: 200; */
    /* font-size: 18px; */
    text-align: center;
  
    margin: auto;
  }
  
  .sidenav a {
    margin-top: 27px !important;
    /* padding: 0px 10px 0px 10px; */
    display: block;
    transition: 0.6s ease;
    color: white;
    width: auto;
    /* border-radius: 5px; */
    cursor: pointer;
    background-color: black;
    /* font-weight: 200; */
    /* font-size: 18px; */
    text-align: center;
  
    margin: auto;
  }






.column {
    margin: 15px 15px 0;
    padding: 0;
  }
  
  .column:last-child {
    padding-bottom: 60px;
  }
  
  .column::after {
    content: '';
    clear: both;
    display: block;
  }
  
  .column div {
    position: relative;
    float: left;
    width: 300px;
    height: 200px;
    margin: 0 0 0 25px;
    padding: 0;
  }
  
  .column div:first-child {
    margin-left: 0;
  }
  
  .column div span {
    position: absolute;
    bottom: -20px;
    left: 0;
    z-index: -1;
    display: block;
    width: 300px;
    margin: 0;
    padding: 0;
    color: #444;
    font-size: 18px;
    text-decoration: none;
    text-align: center;
    transition: 0.3s ease-in-out;
    opacity: 0;
  }
  
  /* Styles for images with hover effect */
  figure {
    /* width: 300px; */
    height: auto; /* Adjusted height for your specific case */
    margin: 0;
    padding: 0;
    background: #fff;
    overflow: hidden;
    border-radius: 20px;
  }
  
  figure:hover + span {
    bottom: -36px;
    opacity: 1;
    
  }
  
  /* Zoom In #1 */
  .hover01 figure img {
    transform: scale(1);
    transition: 0.3s ease-in-out;
  }
  
  .hover01 figure:hover img {
    transform: scale(1.3);
  }










  

.line-clamp3 {
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;  
    overflow: hidden !important;
  }
  


.btn-sm1.active {
    /* Styles for the active state */
    color: white;
    background-color: #0f75bc;
    /* Add any other styles you want to apply when the link is active */
  }





























@media (max-width: 576px) {

   
   
}

@media (max-width: 767px) {
 
    .tabMobile{
        border: 1px solid grey;
        height: 40px;
        width: 250px;
    }

    
.intervieBox{
    display: grid;
}

.intervieBox .box-Card{
    margin-top: 20px;
        
}

.learningBox{
    display: grid;
}

.guestImg{
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: start;
}

.guestText{
    margin-top: 5px;
    margin-bottom: 5px;
    align-items: center;
    
}

.guestFont{
    font-size: 22px;
    width: 90%;
}

.btn-pod{
    margin-top: 10px;
}

.ImgBoxGuets{
    width: 100%;
}

.ImgBoxGuets2{
    width: 100%;
}

.guestPtag{
    font-size: 15px;
}

.guestFont2{
    font-size: 22px;
}
    
.quickImgBox{
    width: 50%;
}

.quickText{
    font-size: 14px;
}
    
  
}

@media (max-width: 992px) {
 
 
}

@media (max-width: 1200px) {
 
}

@media (max-width: 1400px) {
 
}

