.footerPadding {
  padding: 30px 50px 10px 50px;
  color: white;
}

.followPadding {
  padding-right: 3rem;
  padding-left: 3rem;
  margin-top: 50px;
}

.SubBtn {
  margin: auto !important;
}

.FootLogo {
  width: 100%;
  object-fit: contain;
  /* height: 30%; */
  height: 65px !important;
  object-fit: contain !important;
}

.otherPublic {
  width: 50%;
  text-align: center;
  margin: auto;
}

.imgPublica {
  width: 100%;
  margin-top: 10px;
}

.imgPublicaCMO {
  width: 100%;
  margin-top: 10px;
}

.imgET {
  width: 100%;
}
.facebookImg {
  border: 2px solid white;
  margin: auto;
  text-align: center;
  padding: 6px;
  border-radius: 7px;
}


.lazyload-wrapper {
  opacity: 0; /* Initially hide the lazy-loaded component */
  transition: opacity 1.5s ease; /* Add a transition effect for opacity change */
}

.lazyload-wrapper.loaded {
  opacity: 1; /* Show the lazy-loaded component when it's loaded */
}


.lazyload-wrapper.loaded1 {
    opacity: 1; /* Show the lazy-loaded component when it's loaded */
  }

  .lazyload-wrapper.loaded2 {
    opacity: 1; /* Show the lazy-loaded component when it's loaded */
  }

  .lazyload-wrapper.loaded3 {
    opacity: 1; /* Show the lazy-loaded component when it's loaded */
  }

  .lazyload-wrapper.loaded4 {
    opacity: 1; /* Show the lazy-loaded component when it's loaded */
  }
  .lazyload-wrapper.loaded5 {
    opacity: 1; /* Show the lazy-loaded component when it's loaded */
  }

  .lazyload-wrapper.loaded6 {
    opacity: 1; /* Show the lazy-loaded component when it's loaded */
  }

  .lazyload-wrapper.loaded7 {
    opacity: 1; /* Show the lazy-loaded component when it's loaded */
  }

  .lazyload-wrapper.loaded8 {
    opacity: 1; /* Show the lazy-loaded component when it's loaded */
  }

@media (max-width: 576px) {
  .spaceincontent {
    margin-top: 10px !important;
  }

  .spaceincontentbottm {
    margin-bottom: 10px !important;
  }
}

/* @media (min-width: 1024px) {
  .et {
    width: 90px !important;
  }
} */

 /* @media (max-width:1450px){
  .et{
    width: 130px !important;
    margin-left: 5px !important;
  }
}  */

@media (max-width: 769px) {
  .publicImg {
    width: 100%;
  }

  /* .imgPublica{
    width: 3rem;
} */

  .imgPublicaEnter {
    width: 66px !important;
    margin: auto !important;
    /* height: auto !important; */
  }

  .footerPadding {
    padding: 0px;
  }

  .followPadding {
    padding-right: 150px;
    padding-left: 13px;
    margin-top: 15px;
  }

  .FootLogo {
    width: 60%;
    margin-top: 25px;
    object-fit: contain !important;
  }

  .otherPublic {
    width: 100% !important;
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    overflow-x: hidden !important;
    text-align: center !important;
    margin: auto ;
  }

  .it-logo {
    width: 68px !important;
  }

  .talk-logo {
    width: 62px !important;
    margin: auto;
  }

  .dev-logo {
    width: 45px !important;
    margin-bottom: 5px;
    margin: auto;
  }
  .aa {
    display: none;
  }
  .imgPublicaCMO {
    width: 100%;
    margin-top: 5px;
  }
}
