





.box-Card, .card{
    border: transparent;
    
}


.colorRed{
    color: #0f75bc;
}

.AboutLogo{
    align-items: center;
    display: flex;
}


.AboutInterviews{
    align-items: center;
    margin: auto;
    justify-content: space-around;
    display: flex;  
}



.SubBtn{
    background-color: #0f75bc;
  color: white;
  border: transparent;
  padding: 8px;
  width: 130px;
  border-radius: 4px;
  margin-right: 30px;
}

.SubBtn:hover{
    background-color: #0f75bc;
}


.pinkColor{
    color: #0f75bc !important;
}


.box-Card1{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    width: 25rem !important;
    
}



.locationBox{
    display: flex;
}


.emailBox{
    display: flex;
    justify-content: space-around;
}


.goBack{
    border: 1px solid grey;
    padding: 10px;
    font-weight: 500;
    border-radius: 5px;
}

.goBack:hover{
    background-color: #0f75bc;
    color: white !important;
    border: transparent;
}

.notfound{
    margin-top: 100px !important;
    margin-bottom: 100px !important;
}



.invalid-feedback{
    margin-left: 13px;
}





.xmlClasses li{
    list-style-type: none;
    font-size: 13px;
    font-weight: 500;
}






.breadcrumb {
    display: flex;
    border-radius: 10px;
    /* margin: auto; */
    /* text-align: center; */
    /* top: 50%; */
    width: 100%;
    height: 40px;
    /* transform: translateY(-50%); */
    z-index: 1;
    /* justify-content: center; */
    }
    
    
    .breadcrumb__item {
    height: 70%;
    background-color: white;
    color: #252525;
   font-weight: 600;
    border-radius: 7px;
    letter-spacing: 1px;
    transition: all 0.3s ease;
    /* text-transform: uppercase; */
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    transform: skew(-21deg);
    box-shadow: 0 2px 5px rgba(0,0,0,0.26);
    margin: 5px;
    padding: 0px 10px 0px 10px;
    cursor: pointer;
    }
    
    
    .breadcrumb__item:hover {
    background: #0f75bc;
    color: #FFF;
    }
    
    
    .breadcrumb__inner {
    display: flex;
    flex-direction: column;
    margin: auto;
    z-index: 2;
    transform: skew(21deg);  
    }
    
    .breadcrumb__title {
    font-size: 13px;
    text-overflow: ellipsis;  
    overflow: hidden;
    white-space: nowrap;  
    }
    
    
    @media all and (max-width: 1000px) {
    .breadcrumb {
    height: 35px;
    }
    
    .breadcrumb__title{
    font-size: 11px;
    }
    .breadcrumb__item {
    padding: 0 30px;
    }
    }
    
    @media all and (max-width: 710px) {
    .breadcrumb {
    height: 30px;
    }
    .breadcrumb__item {
    padding: 0 20px;
    }
    
    }


  .instaBackColor{
    background-image: linear-gradient(to left bottom, #f9ce34, #ffaf31, #ff903d, #ff704d, #ff5160, #f9396e, #ef1f7e, #e0008f, #cf00a0, #b800b3, #960ec5, #6228d7);
  }

  .share-button-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip {
    visibility: hidden;
    width: 120px;
    background-color: #fcfcf2;
    color: #000;
    text-align: center;
    border: 1px solid #000;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 13px;
  }
  
  .share-button-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
  










@media (max-width: 320px) {

    .box-Card2{
        width: 17rem !important;
        margin: auto !important;
    }
   
}






@media (max-width: 576px) {

   
   
}

@media (max-width: 767px) {
 
    .AboutLogo{
       margin-top: 50px;
    }

    .AboutInterviews{
        display: grid;
    }

  .box-Card1{
    margin-top: 10px;
    width: 22rem !important;
  }

  .locationBox{
    display: grid;
}

.emailBox{
    display: flow;
    
}

    
    
}

@media (max-width: 992px) {
 
 
}

@media (max-width: 1200px) {
 
}

@media (max-width: 1400px) {
 
}






















/* .box-Card, .card{
    border: transparent;
    
}


.colorRed{
    color: #0f75bc;
}

.AboutLogo{
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    
}


.AboutLogo img{
  
    background: none;
    width: 100%;

    height: auto;
    object-fit: cover;
}

.AboutInterviews{
    align-items: center;
    margin: auto;
    justify-content: space-around;
    display: flex;  
}



.SubBtn{
    background-color: #0f75bc;
  color: white;
  border: transparent;
  padding: 8px;
  width: 130px;
  border-radius: 4px;
  margin-right: 30px;
}

.SubBtn:hover{
    background-color: #0f75bc;
}


.pinkColor{
    color: #0f75bc !important;
}


.box-Card1{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    width: 25rem !important;
    
}



.locationBox{
    display: flex;
}


.emailBox{
    display: flex;
    justify-content: space-around;
}


.goBack{
    border: 1px solid grey;
    padding: 10px;
    font-weight: 500;
    border-radius: 5px;
}

.goBack:hover{
    background-color: #0f75bc;
    color: white !important;
    border: transparent;
}

.notfound{
    margin-top: 100px !important;
    margin-bottom: 100px !important;
}



.invalid-feedback{
    margin-left: 13px;
}


























@media (max-width: 320px) {

    .box-Card2{
        width: 17rem !important;
        margin: auto !important;
    }
   
}






@media (max-width: 576px) {

   
   
}

@media (max-width: 767px) {
 
    .AboutLogo{
       margin-top: 50px;
    }

    .AboutInterviews{
        display: grid;
    }

  .box-Card1{
    margin-top: 10px;
    width: 22rem !important;
  }

  .locationBox{
    display: grid;
}

.emailBox{
    display: flow;
    
}

    
    
}



 */
